import Vue from 'vue'
import './plugins'
import './filters'
import router from './router'
import store from './store'
import App from './App.vue'

Vue.config.devtools = process.env.NODE_ENV !== 'production'
Vue.config.performance = process.env.NODE_ENV !== 'production';

/*
Vue.prototype.can = function(name) {
  return store.getters.permissions.includes(name)
}

Vue.prototype.hasRole = function(name) {
  return store.getters.roles.includes(name)
}

Vue.prototype.hasFeature = function(name) {
  return store.getters.features.includes(name)
}
*/

(async function () {
  const token = window.localStorage.getItem('token')
  const user = window.localStorage.getItem('user')
  if (token && user) {
    axios.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${token}`
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    axios.interceptors.response.use(
      (response) => response,
      async (e) => {
        if (e.response.status === 401) {
          await store.dispatch('logout')
        } else {
          this.$message.error(e.response.message)
        }
      }
    )

    try {
      await store.dispatch('setUser', JSON.parse(user))
      await store.dispatch('setToken', token)
      await store.dispatch('setLogged', true)
    } catch (e) {
      await store.dispatch('setLogged', false)
    }
  }

  new Vue({
    el: '#app',
    store,
    router,
    render: h => h(App)
  })
})()
