<template lang="pug">
  center(v-if="!logged")
    img.pt-20(:src="`/images/${logo}`" height="200")
    el-form.p-20.login(v-model="form" style="padding-top:25px!important;width: 350px" @submit.native.prevent="login" @keyup.enter.native="login")
      el-form-item(label="")
        el-input(v-model="form.username" size="medium" placeholder="Enter username or email" @keyup="login")
          template(slot="prepend") KULLANICI
      el-form-item(label="")
        el-input(v-model="form.password" type="password" size="medium" placeholder="Enter password" @keyup="login")
          template(slot="prepend") ŞİFRE
      el-form-item
        el-button(
          type="primary"
          :loading="fetching"
          plain
          @click="login"
          size="medium"
          icon="el-icon-arrow-right"
        ) {{ fetching ? 'WAIT' : 'ENTER' }}
</template>
<script>
import Vue from 'vue'
export default {
  name: 'Login',
  data(){
    return {
      fetching: false,
      logged: false,
      user: null,
      form: {
        username: '',
        password: ''
      },
      logo: process.env.VUE_APP_LOGIN_LOGO
    }
  },
  created(){
    if(window.localStorage.getItem('token')){
      this.logged = true
      this.$router.push({ name: 'dashboard' })
    }
  },
  methods: {
    async login(){
      try{
        this.fetching = true
        const resp = await fetch(window.axios.defaults.baseURL + '/login', {
         method: 'POST',
         headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json' 
         },
         body: JSON.stringify(this.form)
        });
        let content = await resp.json();
        if(content && content.hasOwnProperty('access_token')) {
          this.logged = true
          window.localStorage.setItem('token', content.access_token)
          window.localStorage.setItem('user', JSON.stringify(content)) 
          window.location.reload(true)
        } else {
          this.fetching = false
          this.$message.error('YOUR CREDENTIALS ARE INCORRECT OR YOUR ACCOUNT MIGHT BEEN SUSPENDED')
        }
      }catch(e){
        this.fetching = false
        this.$message.error(e.response.message)
      }
    }
  }
}
</script>
<style>
.login .el-input-group__prepend{
  width: 120px !important;
  text-align: right !important;
}
</style>