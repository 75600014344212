import Vue from 'vue'
import Router from 'vue-router'

const Dashboard = () => import(/* webpackChunkName: "system/dashboard" */'@/pages/system/Dashboard')
const Settings = () => import(/* webpackChunkName: "system/settings" */'@/pages/system/Settings')
const Profile = () => import(/* webpackChunkName: "system/profle" */'@/pages/system/Profile')

const Sales = () => import(/* webpackChunkName: "main/sale" */'@/pages/main/Sales')
const Sale = () => import(/* webpackChunkName: "main/sale" */'@/pages/main/Sale')
const Entries = () => import(/* webpackChunkName: "main/entries" */'@/pages/main/Entries')
const Stock = () => import(/* webpackChunkName: "main/stock" */'@/pages/main/Stock')

const Users = () => import(/* webpackChunkName: "definitions/users" */'@/pages/definitions/Users')
const Sellers = () => import(/* webpackChunkName: "definitions/sellers" */'@/pages/definitions/Sellers')
const Dealers = () => import(/* webpackChunkName: "definitions/dealers" */'@/pages/definitions/Dealers')
const Customers = () => import(/* webpackChunkName: "definitions/customers" */'@/pages/definitions/Customers')
const Suppliers = () => import(/* webpackChunkName: "definitions/suppliers" */'@/pages/definitions/Suppliers')
const Agencies = () => import(/* webpackChunkName: "definitions/agencies" */'@/pages/definitions/Agencies')
const Guides = () => import(/* webpackChunkName: "definitions/guides" */'@/pages/definitions/Guides')
const Taxi = () => import(/* webpackChunkName: "definitions/taxi" */'@/pages/definitions/Taxi')
const TaxiDrivers = () => import(/* webpackChunkName: "definitions/taxi-drivers" */'@/pages/definitions/TaxiDrivers')
const ProductCategories = () => import(/* webpackChunkName: "definitions/product-categories" */'@/pages/definitions/ProductCategories')
const Branches = () => import(/* webpackChunkName: "definitions/branches" */'@/pages/definitions/Branches')
const Warehouses = () => import(/* webpackChunkName: "definitions/warehouses" */'@/pages/definitions/Warehouses')

const CashHandover = () => import(/* webpackChunkName: "accounting/cash-handover" */'@/pages/accounting/CashHandover')
const Balances = () => import(/* webpackChunkName: "accounting/balances" */'@/pages/accounting/Balances')
const Payments = () => import(/* webpackChunkName: "accounting/payments" */'@/pages/accounting/Payments')
const Salaries = () => import(/* webpackChunkName: "accounting/salaries" */'@/pages/accounting/Salaries')
const Currencies = () => import(/* webpackChunkName: "accounting/currencies" */ '@/pages/accounting/Currencies')
const ExchangeRates = () => import(/* webpackChunkName: "accounting/exchangerates" */ '@/pages/accounting/ExchangeRates')

//const SalesReport = () => import(/* webpackChunkName: "reports/sales" */'@/pages/reports/SalesReport')
//const DailySalesReport = () => import(/* webpackChunkName: "reports/daily-sales" */'@/pages/reports/DailySalesReport')
//const PeriodicSalesReport = () => import(/* webpackChunkName: "reports/periodic-sales" */'@/pages/reports/PeriodicSalesReport')
const StockReport = () => import(/* webpackChunkName: "reports/stock" */'@/pages/reports/StockReport')
const StockCountReport = () => import(/* webpackChunkName: "reports/stock-count" */'@/pages/reports/StockCountReport')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { title: 'AYARLAR' }
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { title: 'HESABIM' }
    },
    {
      path: '/sales',
      name: 'sales',
      component: Sales,
      meta: { title: 'SATIŞLAR' }
    },
    {
      path: '/sales/:id',
      name: 'sale',
      component: Sale,
      meta: { parent: { path: 'sales', title: 'SATIŞLAR' }, title: 'YENİ SATIŞ' }
    },
    {
      path: '/entries',
      name: 'entries',
      component: Entries,
      meta: { title: 'MAĞAZA GİRİŞLERİ' }
    },
    {
      path: '/stock',
      name: 'stock',
      component: Stock,
      meta: { title: 'STOK YÖNETİMİ' }
    },
    {
      path: '/accounting/balances',
      name: 'balances',
      component: Balances,
      meta: { title: 'BAKİYELER', parent: { title: 'MUHASEBE' } }
    },
    {
      path: '/accounting/cash-handover',
      name: 'cash-handover',
      component: CashHandover,
      meta: { title: 'KASA TESLİM', parent: { title: 'MUHASEBE' } }
    },
    {
      path: '/accounting/payments',
      name: 'payments',
      component: Payments,
      meta: { title: 'ÖDEMELER & GİDERLER', parent: { title: 'MUHASEBE' } }
    },
    {
      path: '/accounting/salaries',
      name: 'salaries',
      component: Salaries,
      meta: { title: 'MAAŞLAR', parent: { title: 'MUHASEBE' } }
    },
    {
      path: '/suppliers',
      name: 'suppliers',
      component: Suppliers,
      meta: { title: 'TEDARİKÇİLER' }
    },
    {
      path: '/agencies',
      name: 'agencies',
      component: Agencies,
      meta: { title: 'ACENTALAR' }
    },
    {
      path: '/guides',
      name: 'guides',
      component: Guides,
      meta: { title: 'REHBERLER' }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: { title: 'KULLANICILAR' }
    },
    {
      path: '/sellers',
      name: 'sellers',
      component: Sellers,
      meta: { title: 'SATICILAR' }
    },
    {
      path: '/dealers',
      name: 'dealers',
      component: Dealers,
      meta: { title: 'HANUTÇULAR' }
    },
    {
      path: '/customers',
      name: 'customers',
      component: Customers,
      meta: { title: 'MÜŞTERİLER' }
    },
    {
      path: '/taxi',
      name: 'taxi',
      component: Taxi,
      meta: { title: 'TAKSİLER' }
    },
    {
      path: '/taxi/drivers',
      name: 'taxi-drivers',
      component: TaxiDrivers,
      meta: { title: 'TAKSİ ŞOFÖRLERİ' }
    },
    {
      path: '/product-categories',
      name: 'product-categories',
      component: ProductCategories,
      meta: { title: 'ÜRÜN KATEGORİLERİ' }
    },
    {
      path: '/branches',
      name: 'branches',
      component: Branches,
      meta: { title: 'ŞUBELER' }
    },
    {
      path: '/warehouses',
      name: 'warehouses',
      component: Warehouses,
      meta: { title: 'DEPOLAR' }
    },
    {
      path: '/currencies',
      name: 'currencies',
      component: Currencies,
      meta: { title: 'PARA BİRİMLERİ', parent: { title: 'MUHASEBE' } }
    },
    {
      path: '/exchangerates',
      name: 'exchange-rates',
      component: ExchangeRates,
      meta: { title: 'DÖVİZ KURLARI', parent: { title: 'MUHASEBE' } }
    },
    {
      path: '/reports/stock',
      name: 'stock-report',
      component: StockReport,
      meta: { parent: { title: 'RAPORLAR' }, title: 'STOK RAPORU' }
    },
    {
      path: '/reports/stock-count',
      name: 'stock-count-report',
      component: StockCountReport,
      meta: { parent: { title: 'RAPORLAR' }, title: 'STOK SAYIM RAPORU' }
    },
    /*
    {
      path: '/reports/sales',
      name: 'sales-report',
      component: SalesReport,
      meta: { parent: { title: 'RAPORLAR' }, title: 'SATIŞ RAPORU' }
    },
    {
      path: '/reports/daily-sales',
      name: 'daily-sales-report',
      component: DailySalesReport,
      meta: { parent: { title: 'RAPORLAR' }, title: 'GÜNLÜK SATIŞ RAPORU' }
    },
    {
      path: '/reports/periodic-sales',
      name: 'periodic-sales-report',
      component: PeriodicSalesReport,
      meta: { parent: { title: 'RAPORLAR' }, title: 'DÖNEMSEL SATIŞ RAPORU' }
    }
    */
  ]
})
