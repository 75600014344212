import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.headers.common.Accept = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.response.use(
  (res) => res,
  (e) => {
    if (e.response.status === 401) {
      window.localStorage.removeItem('token')
      window.location.reload(true)
    }
    return Promise.reject(e)
  }
)

window.axios = axios
