<template lang="pug">
#app.be-wrapper.be-nosidebar-left
  vue-progress-bar
  main-nav(v-if="logged && user.active")
  .be-content(v-if="logged && user.active")
    .main-content.container-fluid
      nav(aria-label="breadcrumb" role="navigation" v-if="$route.name !== 'dashboard'")
        ol.breadcrumb
          li.breadcrumb-item
            router-link(:to="{ name: 'dashboard' }"): span.icon.mdi.mdi-home
          li.breadcrumb-item(v-if="$route.meta && $route.meta.parent")
            router-link(v-if="$route.meta.parent.path" :to="{ name: $route.meta.parent.path }") {{ $route.meta.parent.title }}
            span(v-if="!$route.meta.parent.path") {{ $route.meta.parent.title }}
          li.breadcrumb-item.active(v-if="$route.meta && $route.meta.title") {{ $route.meta.title }}
      router-view
  login(v-if="!logged")
</template>
<script>
import MainNav from '@/components/MainNav'
import Login from '@/components/Login'
export default {
  name: 'app',
  components: {
    MainNav,
    Login
  },
  data() {
    return {
      menuOpened: false
    }
  },
  async mounted() {
    axios.interceptors.response.use(
      (response) => response,
      async (e) => {
        if (e.response.status === 401) {
          await store.dispatch('logout')
        } else {
          this.$message.error(e.response.message)
        }
      }
    )
    window.document.title = process.env.VUE_APP_MAIN_TITLE
  },
  async created(){
    window.addEventListener('resize', (e) => {
      this.$store.commit('set', ['windowHeight', e.target.innerHeight])
    })
    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })
  },

  computed: {
    user: function(){
      return this.$store.state.user
    },
    logged: function(){
      return this.$store.state.logged
    },
    drawer: function(){
      return this.$store.state.drawer
    },
    dialog: function(){
      return this.$store.state.dialog
    }
  }
}
</script>