import Vuex from "vuex";
import Vue from "vue";
import get from "lodash/get";
import set from "lodash/set";
import en from "element-ui/lib/locale/lang/en";
import ru from "element-ui/lib/locale/lang/ru-RU";
import tr from "element-ui/lib/locale/lang/tr-TR";
import locale from "element-ui/lib/locale";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  state: {
    logged: false,
    settings: null,
    errors: [],
    windowHeight: window.innerHeight,
    loading: { jobs: false },
    globalLoading: false,
    features: [],
    companies: [],
    warehouses: [],
    branches: [],
    branch: {
      id: null,
      name: "",
    },
    locations: [],
    destinations: [],
    categories: [],
    suppliers: [],
    markets: [],
    currencies: [],
    currency: process.env.VUE_APP_DEFAULT_CURRENCY || "USD",
    languages: [],
    guides: [],
    drivers: [],
    users: [],
    user: {
      active: false,
      name: "",
      defaults: {
        systemLanguage: "en",
      },
      roles: [],
      locations: [],
      companies: [],
      permissions: [],
      branches: [],
      warehouses: [],
      main_company_id: null,
      hasPendingSurvey: false,
    },
    token: null,
    permissions: [],
    roles: [],
    types: [],
    drawer: {
      size: "30%",
      show: false,
      component: null,
      props: null,
      title: null,
      direction: "rtl",
    },
    dialog: {
      width: "30%",
      show: false,
      component: null,
      props: null,
      title: null,
    },
    dateranges: {
      firstDayOfWeek: 1,
      format: "dd.MM.yyyy HH:mm",
      shortcuts: [
        {
          text: "Son 7 gün",
          onClick(picker) {
            const start = window
              .dayjs()
              .subtract(1, "w")
              .startOf("day")
              .toDate();
            const end = window.dayjs().endOf("day").toDate();
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Son 1 ay",
          onClick(picker) {
            const start = window
              .dayjs()
              .subtract(1, "M")
              .startOf("day")
              .toDate();
            const end = window.dayjs().endOf("day").toDate();
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Son 3 ay",
          onClick(picker) {
            const start = window
              .dayjs()
              .subtract(3, "M")
              .startOf("day")
              .toDate();
            const end = window.dayjs().endOf("day").toDate();
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Dün",
          onClick(picker) {
            const start = window
              .dayjs()
              .subtract(1, "d")
              .startOf("day")
              .toDate();
            const end = window.dayjs().subtract(1, "d").endOf("day").toDate();
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Bugün",
          onClick(picker) {
            const start = window.dayjs().startOf("day").toDate();
            const end = window.dayjs().endOf("day").toDate();
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    seasons: [2017, 2018, 2019, 2020, 2021, 2022],
  },
  getters: {
    roles: (state) => state.user.roles,
    permissions: (state) => state.user.permissions,
    features: (state) => state.features,
  },
  mutations: {
    set(state, obj) {
      state[obj[0]] = obj[1];
    },
    fill(state, obj) {
      set(state, obj[0], obj[1]);
    },
    add(state, obj) {
      get(state, obj[0]).push(obj[1]);
    },
    replace(state, obj) {
      const items = get(state, obj[0]);
      if (obj[1] && obj[1].hasOwnProperty("id")) {
        const index = items.findIndex((item) => item.id === obj[1].id);
        state[obj[0]].splice(index, 1, obj[1]);
      } else {
        set(state, obj[0], obj[1]);
      }
    },
    remove(state, obj) {
      const items = get(state, obj[0]);
      if (obj[1].hasOwnProperty("id")) {
        const index = items.findIndex((item) => item.id === obj[1].id);
        state[obj[0]].splice(index, 1);
      } else {
        state[obj[0]].splice(obj[1], 1);
      }
    },
  },
  actions: {
    async setLogged({ state }, val) {
      state.logged = val;
      return true;
    },
    async setToken({ state }, val) {
      state.token = val;
      return true;
    },
    async setUser({ commit, dispatch }, data) {
      try {
        const user = data.user;

        commit("fill", ["user", user]);

        const branch = window.localStorage.getItem('branch');

        await dispatch('setBranch', branch ? JSON.parse(branch) : user.branches[0]);

        commit("fill", ["warehouses", user.warehouses]);

        commit("fill", ["branches", user.branches]);

        Vue.prototype.can = function (name) {
          return user.permissions.includes(name) && user.active === true;
        };

        Vue.prototype.hasRole = function (role) {
          return user.roles.includes(role) && user.active === true;
        };

        Vue.prototype.hasFeature = function (a) {
          const features = get(data, "features", []);
          return features.includes(a);
        };

        const userLang = get(user, "defaults.systemLanguage", "tr");
        if (userLang) {
          let activeLang = en;
          if (userLang == "en") {
            activeLang = en;
          } else if (userLang == "tr") {
            activeLang = tr;
          } else if (userLang == "ru") {
            activeLang = ru;
          }
          locale.use(activeLang);
          dayjs.locale(userLang);
        }

        await dispatch("change", ["currencies", data.currencies]);
        await dispatch("change", ["types", data.types]);
        await dispatch("change", ["settings", data.settings]);
        await dispatch("change", ["features", data.features]);

        // await dispatch('change', ['branch', data.user.branches[0]])

        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async logout({ state }) {
      state.logged = false;
      window.localStorage.removeItem("token");
    },
    async setBranch({ dispatch, state }, branch) {
      await dispatch('fill', ['branch', branch])
      window.localStorage.setItem('branch', JSON.stringify(branch))
    },
    sync({ commit, dispatch, state }, path) {
      commit("replace", ["loading." + path, true]);
      return new Promise((resolve, reject) => {
        axios
          .post(path, { items: state[path] })
          .then((res) => {
            commit("fill", [path, res.data]);
            commit("replace", ["loading." + path, false]);
            resolve(res);
          })
          .catch((errors) => {
            commit("replace", ["loading." + path, false]);
            reject(errors);
          });
      });
    },
    fetch({ commit, dispatch }, obj) {
      let path = null;
      let params = null;
      if (typeof obj === "string") {
        path = obj;
      } else {
        path = obj[0];
        params = obj[1];
      }
      commit("replace", ["loading." + path, true]);
      return new Promise((resolve, reject) => {
        axios
          .get(`${path}`, { params })
          .then((res) => {
            commit("fill", [path, res.data]);
            commit("replace", ["loading." + path, false]);
            resolve(res);
          })
          .catch((errors) => {
            reject(errors);
          });
      });
    },
    store({ commit, dispatch }, obj) {
      commit("replace", ["loading." + obj[0], true]);
      return new Promise((resolve, reject) => {
        axios
          .post(`${obj[0]}`, obj[1])
          .then((res) => {
            if (obj[2] === true) {
              dispatch("add", [obj[0], res.data]);
            }
            commit("replace", ["loading." + obj[0], false]);
            resolve(res);
          })
          .catch((errors) => {
            commit("replace", ["loading." + obj[0], false]);
            reject(errors);
          });
      });
    },
    update({ commit, dispatch }, obj) {
      commit("replace", ["loading." + obj[0], true]);
      return new Promise((resolve, reject) => {
        axios
          .put(`${obj[0]}/${obj[1].id}`, obj[1])
          .then((res) => {
            if (obj[2] === true) {
              dispatch("change", [obj[0], res.data]);
            }
            commit("replace", ["loading." + obj[0], false]);
            resolve(res);
          })
          .catch((errors) => {
            commit("replace", ["loading." + obj[0], false]);
            reject(errors);
          });
      });
    },
    destroy({ commit, dispatch, state }, obj) {
      commit("replace", ["loading." + obj[0], true]);
      return new Promise((resolve, reject) => {
        if (obj[1] !== undefined && obj[1] !== null) {
          let item;
          if (obj[1].hasOwnProperty("id")) {
            item = obj[1];
          } else {
            item = state[obj[0]][obj[1]];
          }
          if (item.hasOwnProperty("id")) {
            axios
              .delete(`${obj[0]}/${item.id}`)
              .then((res) => {
                if (res.data.success) {
                  commit("remove", obj);
                  commit("replace", ["loading." + obj[0], false]);
                  resolve(res);
                }
              })
              .catch((errors) => {
                commit("replace", ["loading." + obj[0], false]);
                reject(errors);
              });
          } else {
            commit("remove", obj);
            commit("replace", ["loading." + obj[0], false]);
            resolve(true);
          }
        }
      });
    },
    restore({ commit, dispatch }, obj) {
      commit("replace", ["loading." + obj[0], true]);
      return new Promise((resolve, reject) => {
        axios
          .post(`${obj[0]}/restore/${obj[1]}`)
          .then((res) => {
            if (res.data.success) {
              commit("replace", ["loading." + obj[0], false]);
              resolve(res);
            }
          })
          .catch((errors) => {
            commit("replace", ["loading." + obj[0], false]);
            reject(errors);
          });
      });
    },
    change({ commit }, obj) {
      return new Promise((resolve, reject) => {
        commit("replace", obj);
        resolve(obj);
      });
    },
    fill({ commit }, obj) {
      return new Promise((resolve, reject) => {
        commit("fill", obj);
        resolve(obj);
      });
    },
    unset({ commit }, path) {
      commit("replace", [path, []]);
    },
    remove({ commit }, obj) {
      if (typeof obj === "string") {
        commit("replace", [obj, null]);
      } else {
        commit("remove", obj);
      }
    },
    add({ commit }, obj) {
      commit("add", obj);
    },
    toggle({ commit, state }, path) {
      commit("replace", [path, !get(state, path)]);
    },
    errors({ commit }, errors) {
      commit("fill", ["errors", errors]);
    },
  },
});
