import VueBarcodeScanner from 'vue-barcode-scanner'
import Vue from 'vue'
const options = {
  sound: true, // default is false
  soundSrc: '/static/sound.wav', // default is blank
  sensitivity: 300, // default is 100
  requiredAttr: true, // default is false
  controlSequenceKeys: ['NumLock', 'Clear'], // default is null
  callbackAfterTimeout: false // default is false
}

Vue.use(VueBarcodeScanner, options)
