<template>
  <nav class="navbar navbar-expand fixed-top be-top-header">
    <div class="container-fluid">
      <div class="be-navbar-header">
        <router-link
          :to="{ name: 'dashboard' }"
          class="navbar-brand"
          :style="`background-image: url(../images/${logo}) !important;background-size: auto 50px;`"
        ></router-link>
      </div>
      <a class="be-toggle-top-header-menu collapsed" href="#" data-toggle="collapse" aria-expanded="false" data-target="#be-navbar-collapse">MENÜ</a>
      <div class="navbar-collapse collapse" id="be-navbar-collapse">
        <ul class="navbar-nav">
          <li class="nav-item"><router-link :to="{ name: 'dashboard' }" class="nav-link">Güncel durum</router-link></li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
              Satışlar <span class="mdi mdi-caret-down"></span>
            </a>
            <div class="dropdown-menu" role="menu">
              <router-link :to="{ name: 'sale', params: { id: 'new' } }" class="nav-link" v-if="permission('sales.create')">Yeni satış</router-link>
              <router-link :to="{ name: 'sales' }" class="nav-link" v-if="permission('sales.list')">Tüm satışlar</router-link>
            </div>
          </li>

          <li class="nav-item"><router-link :to="{ name: 'entries' }" class="nav-link" v-if="hasFeature('entries') && permission('entries.list')">Girişler</router-link></li>

          <li class="nav-item"><router-link :to="{ name: 'stock' }" class="nav-link" v-if="permission('products.list')">Stok</router-link></li>

          <li class="nav-item dropdown" v-if="role('accountant')">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
              Muhasebe <span class="mdi mdi-caret-down"></span>
            </a>
            <div class="dropdown-menu" role="menu">
              <router-link :to="{ name: 'cash-handover' }" class="dropdown-item">Kasa Teslim</router-link>
              <router-link :to="{ name: 'balances' }" class="dropdown-item" v-if="hasFeature('balances')">Bakiyeler</router-link>
              <router-link :to="{ name: 'payments' }" class="dropdown-item" v-if="hasFeature('payments')">Ödemeler &amp; Giderler</router-link>
              <!--router-link :to="{ name: 'invoices' }" class="dropdown-item">Tedarikçi faturaları</router-link-->
              <!--router-link :to="{ name: 'salaries' }" class="dropdown-item">Personel maaşları</router-link-->
              <hr />
              <router-link :to="{ name: 'currencies' }" class="dropdown-item">Para birimleri</router-link>
              <router-link :to="{ name: 'exchange-rates' }" class="dropdown-item">Doviz kurları</router-link>
            </div>
          </li>
          <li class="nav-item dropdown" v-if="role('accountant')">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
              Raporlar <span class="mdi mdi-caret-down"></span>
            </a>
            <div class="dropdown-menu" role="menu">
              <!--router-link :to="{ name: 'sales-report' }" class="dropdown-item">Satış raporu</router-link-->
              <!--router-link :to="{ name: 'daily-sales-report' }" class="dropdown-item">Günlük satış raporu</router-link-->
              <!--router-link :to="{ name: 'periodic-sales-report' }" class="dropdown-item">Dönemsel satış raporu</router-link-->
              <!--hr /-->
              <router-link :to="{ name: 'stock-report' }" class="dropdown-item">Stok raporu</router-link>
              <router-link :to="{ name: 'stock-count-report' }" class="dropdown-item">Stok sayım raporu</router-link>
            </div>
          </li>
          <li class="nav-item dropdown" v-if="role('admin')">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
              Tanımlar <span class="mdi mdi-caret-down"></span>
            </a>
            <div class="dropdown-menu" role="menu">
              <router-link :to="{ name: 'users' }" class="dropdown-item" v-if="permission('users.list')">Kullanıcılar</router-link>
              <router-link :to="{ name: 'sellers' }" class="dropdown-item" v-if="permission('sellers.list')">Satıcılar</router-link>
              <router-link :to="{ name: 'agencies' }" class="dropdown-item" v-if="hasFeature('agencies') && permission('agencies.list')">Acentalar</router-link>
              <router-link :to="{ name: 'guides' }" class="dropdown-item" v-if="hasFeature('guides') && permission('guides.list')">Rehberler</router-link>
              <router-link :to="{ name: 'taxi' }" class="dropdown-item" v-if="hasFeature('taxis') && permission('taxi.list')">Taksiler</router-link>
              <router-link :to="{ name: 'taxi-drivers' }" class="dropdown-item" v-if="hasFeature('taxi.drivers') && permission('taxi.drivers.list')">Taksi şoförleri</router-link>
              <router-link :to="{ name: 'dealers' }" class="dropdown-item" v-if="hasFeature('dealers') && permission('dealers.list')">Hanutçular</router-link>
              <router-link :to="{ name: 'customers' }" class="dropdown-item" v-if="hasFeature('customers')">Müşteriler</router-link>
              <router-link :to="{ name: 'suppliers' }" class="dropdown-item" v-if="permission('suppliers.list')">Tedarikçiler</router-link>
              <router-link :to="{ name: 'product-categories' }" class="dropdown-item" v-if="permission('product.categories.list	')">Ürün kategorileri</router-link>
              <router-link :to="{ name: 'branches' }" class="dropdown-item" v-if="hasFeature('branches') && permission('branches.list	')">Şuberler</router-link>
              <router-link :to="{ name: 'warehouses' }" class="dropdown-item" v-if="hasFeature('warehouses') && permission('warehouses.list')">Depolar</router-link>
            </div>
          </li>
        </ul>
      </div>
      <div class="be-right-navbar">
        <ul class="nav navbar-nav float-right be-user-nav">
          <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
            <img :src="`/images/${logo}`" alt="Avatar"><span class="user-name">{{ user.name }}</span></a>
            <div class="dropdown-menu" role="menu">
              <div class="user-info">
                <div class="user-name">{{ user.name }}</div>
              </div>
              <router-link :to="{ name: 'profile' }" class="dropdown-item" v-if="hasFeature('account')">
                <span class="icon mdi mdi-face"></span>Hesabım
              </router-link>
              <router-link :to="{ name: 'settings' }" class="dropdown-item" v-if="hasFeature('settings')">
                <span class="icon mdi mdi-settings"></span>Ayarlar
              </router-link>
              <a class="dropdown-item" @click="logout"><span class="icon mdi mdi-power"></span>Çıkış</a>
            </div>
          </li>
        </ul>
        <ul class="nav navbar-nav float-right be-icons-nav">

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false"><span class="icon mdi mdi-my-location"></span> &nbsp;{{ $store.state.branch.name }} <span class="mdi mdi-caret-down"></span></a>
            <ul class="dropdown-menu be-notifications">
              <li>
                <div class="list">
                  <div class="be-scroller-notifications">
                    <div class="content">
                      <ul>
                        <li class="notification" v-for="(n, i) in $store.state.user.branches" :key="i">
                          <a href="javascript:;" @click="changeBranch(n)">
                            <div class="notification-info">
                              <div class="text">
                                <span class="user-name">{{ n.name }}</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" v-if="hasFeature('settings')">
            <router-link :to="{ name: 'settings' }" class="nav-link be-toggle-right-sidebar">
              <span class="icon mdi mdi-settings"></span>
            </router-link>
          </li>
          <li class="nav-item dropdown" v-if="hasFeature('notifications')">
            <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false"><span class="icon mdi mdi-notifications"></span><span class="indicator"></span></a>
            <ul class="dropdown-menu be-notifications">
              <li>
                <div class="title">Bildirimler<span class="badge badge-pill">{{ notifications.length }}</span></div>
                <div class="list">
                  <div class="be-scroller-notifications">
                    <div class="content">
                      <ul>
                        <li class="notification notification-unread" v-for="(n, i) in notifications" :key="i">
                          <a href="#">
                            <div class="image"><img src="assets/img/avatar2.png" alt="Avatar"></div>
                            <div class="notification-info">
                              <div class="text">
                                <span class="user-name">{{ n.name }}</span> {{ n.message }}
                              </div>
                              <span class="date">{{ n.created_at|dayjs('DD.MM.YYYY / HH:mm') }}</span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="footer"> <a href="#">Tüm bildirimler</a></div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Notifications from '@/components/Notifications'
export default {
  name: 'main-nav',
  components: { Notifications },
  data(){
    return {
      loggingOut: false,
      notifications: [],
      activeMenus: [],
      logo: process.env.VUE_APP_NAV_LOGO
    }
  },
  methods: {
    clearCache(){
      localStorage.clear()
      window.location.reload(true)
    },
    async changeBranch(branch) {
      await this.$store.dispatch('setBranch', branch)
      this.$message.success('ŞUBE DEĞİŞTİ')
      window.location.reload(true)
    },
    async logout(){
      this.loggingOut = true
      await axios.post('logout')
      delete axios.defaults.headers.common.Authorization
      window.localStorage.removeItem('token')
      this.$store.state.user = {
        name: '',
        active: false,
        roles: [],
        permissions: [],
        hasPendingSurvey: false
      }
      this.loggingOut = false
      this.$router.go()
    },
    logs(){
      window.location.href = '/logs'
    },
    role(name) {
      return this.user.roles.includes(name)
    },
    permission(name) {
      return this.user.permissions.includes(name)
    },
    async logout() {
      this.loggingOut = true
      window.localStorage.removeItem('token')
      delete window.axios.defaults.headers.common.Authorization
      this.$store.state.user = {
        name: '',
        active: false,
        roles: [],
        permissions: [],
        hasPendingSurvey: false,
      };
      this.loggingOut = false;
      this.$router.go();
      await window.axios.post('logout')
    },
  },
  computed: {
    user: function(){
      return this.$store.state.user
    },
    activeRoute: function(){
      return this.$route.name
    }
  }
}
</script>
<style type="text/css">
  .be-top-header .navbar-nav>li>a.nav-link { font-size: 17px; }
</style>
